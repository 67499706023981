<template>
  <div class="scoped_cover_div">
    <div class="jobs_table sp">
      <div class>
      <ValidationObserver ref="observer" v-slot="{ invalid }" slim>
        <section>
          <div class="form_section1">
            <table>
              <tr>
                <td>
                  <ValidationProvider name="subject" rules="required|max:30" v-slot="{ errors }" slim>
                  <div class="label_and_element_wrapper">
                    <label>
                        Subject
                      <span>*</span>
                    </label>
                    <input type="text" placeholder="Subject Character Limit 30" v-model="email_template.subject"  :class="errors[0] ? 'warning-border':''" :maxlength='max'/>
                  </div>
                  </ValidationProvider>
                </td>
                <td>
                  <ValidationProvider name="title" rules="required|max:30" v-slot="{ errors }" slim>
                  <div class="label_and_element_wrapper">
                    <label>
                        Title
                      <span>*</span>
                    </label>
                    <input type="text" placeholder="Title Character Limit 30" v-model="email_template.title"  :class="errors[0] ? 'warning-border':''" :maxlength='max'/>
                  </div>
                  </ValidationProvider>
                </td>
              </tr>
              <tr>
                <td>
                  <ValidationProvider name="description" rules="required|max:30" v-slot="{ errors }" slim>
                  <div class="label_and_element_wrapper">
                    <label>
                      Description
                      <span>*</span>
                    </label>
                    <input type="text" placeholder="Description Character Limit 60" v-model="email_template.description"  :class="errors[0] ? 'warning-border':''" :maxlength='max2'/>
                  </div>
                  </ValidationProvider>
                </td>
                <td>
                  <ValidationProvider name="Mailroom" rules="required" v-slot="{ errors }" slim>
                    <div class="label_and_element_wrapper">
                        <label>
                            Mailroom Location
                        <span>*</span>
                        </label>
                        <select v-model="email_template.division_id" :class="errors[0] ? 'warning-border':''">
                            <option value="" disabled>Select Mailroom Location</option>
                            <option :value="division.id" v-for="(division,i) in divisions" :key="'division_'+i">{{division.name}}</option>
                        </select>
                    </div>
                  </ValidationProvider>
                </td>
              </tr>
            </table>
          </div>
        </section>
      </ValidationObserver>

        <section class="centered">
          <a class="link_bt bt_save" @click="submit">Save</a>
        </section>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Department",
  created(){
      this.fetchDivisions();
  },
  data(){
    return {
      max:30,
      max2:60,
      email_template:{
        subject:"",
        title : "",
        description:"",
        division_id:""
      },
      divisions:[]
    }
  },
  methods: {
    submit(){
      this.$refs.observer.validate();
      this.axios.post("/api/emailtemplate/",this.email_template)
        .then(response => {
            this.toast.success(response.data.msg);
            this.$emit('redirect');
        })
        .catch(error =>{
          console.log(error);
        });
    },
    fetchDivisions(){
      this.axios.get("api/division")
      .then(response => {
        console.log(response);
          this.divisions = response.data.divisions;
      })
      .catch(error => {
          console.log(error);
      });
    }
  }
};
</script>
<style  scoped >
.scoped_cover_div {
  margin: 0;
}
.scoped_cover_div section {
  padding: 0;
}
.scoped_cover_div .form_section1 {
  margin-top: 0;
}
.jobs_table.sp table {
  width: 100%;
}
.jobs_table.sp table,
.jobs_table.sp table tr td {
  border: 0;
  padding: 0 0 20px 0;
  vertical-align: top;
}
.jobs_table.sp table tr td {
  width: 33%;
}
.colspan_flex_adj {
  display: flex;
  justify-content: space-around;
}
.colspan_flex_adj .label_and_element_wrapper {
  width: 100%;
}
</style>