var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"scoped_cover_div"},[_c('div',{staticClass:"jobs_table sp2"},[_c('ValidationObserver',{ref:"observer",attrs:{"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('table',[_c('tr',[_c('th',[_vm._v("Subject")]),_c('th',[_vm._v("Title")]),_c('th',[_vm._v("Description")]),_c('th',[_vm._v("Mailroom Location")]),_c('th',[_vm._v("Action")])]),_vm._l((_vm.email_templates),function(data,i){return _c('tr',{key:i},[_c('td',[_c('ValidationProvider',{attrs:{"name":"subject","rules":"required|max:30","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"label_and_element_wrapper"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(data.subject),expression:"data.subject"}],class:errors[0] ? 'warning-border':'',attrs:{"type":"text","placeholder":"Enter Character Limit 30","disabled":!data.is_edit_mode,"maxlength":_vm.max},domProps:{"value":(data.subject)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(data, "subject", $event.target.value)}}})])]}}],null,true)})],1),_c('td',[_c('ValidationProvider',{attrs:{"name":"title","rules":"required|max:30","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"label_and_element_wrapper"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(data.title),expression:"data.title"}],class:errors[0] ? 'warning-border':'',attrs:{"type":"text","placeholder":"Enter Character Limit 30","disabled":!data.is_edit_mode,"maxlength":_vm.max},domProps:{"value":(data.title)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(data, "title", $event.target.value)}}})])]}}],null,true)})],1),_c('td',[_c('ValidationProvider',{attrs:{"name":"description","rules":"required|max:30","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"label_and_element_wrapper"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(data.description),expression:"data.description"}],class:errors[0] ? 'warning-border':'',attrs:{"type":"text","placeholder":"Enter Character Limit 30","disabled":!data.is_edit_mode,"maxlength":_vm.max},domProps:{"value":(data.description)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(data, "description", $event.target.value)}}})])]}}],null,true)})],1),_c('td',[_c('ValidationProvider',{attrs:{"name":"loc","rules":"required","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"label_and_element_wrapper"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(data.division_id),expression:"data.division_id"}],class:errors[0] ? 'warning-border':'',attrs:{"disabled":!data.is_edit_mode},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(data, "division_id", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":"","disabled":""}},[_vm._v("Select MailRoom Location")]),_vm._l((_vm.divisions),function(division,i){return _c('option',{key:'division_'+i,domProps:{"value":division.id}},[_vm._v(_vm._s(division.name))])})],2)])]}}],null,true)})],1),_c('td',{staticClass:"righted"},[_c('a',{staticClass:"table_action_btn",attrs:{"title":"DELETE"},on:{"click":function($event){return _vm.deleteEmailTemplate(data.id)}}},[_vm._v("DELETE")]),(data.is_edit_mode)?_c('a',{staticClass:"table_action_btn",attrs:{"title":"UPDATE"},on:{"click":function($event){return _vm.updateEmailTemplate(data)}}},[_vm._v("UPDATE")]):_vm._e(),(data.is_edit_mode)?_c('a',{staticClass:"table_action_btn",attrs:{"title":"CANCEL"},on:{"click":function($event){return _vm.cancelEmailTemplate()}}},[_vm._v("CANCEL")]):_vm._e(),(!data.is_edit_mode)?_c('a',{staticClass:"table_action_btn",attrs:{"title":"EDIT"},on:{"click":function($event){data.is_edit_mode=true}}},[_vm._v("EDIT")]):_vm._e()])])})],2)]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }