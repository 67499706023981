var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"scoped_cover_div"},[_c('div',{staticClass:"jobs_table sp"},[_c('div',{},[_c('ValidationObserver',{ref:"observer",attrs:{"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('section',[_c('div',{staticClass:"form_section1"},[_c('table',[_c('tr',[_c('td',[_c('ValidationProvider',{attrs:{"name":"subject","rules":"required|max:30","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"label_and_element_wrapper"},[_c('label',[_vm._v("\n                      Subject\n                    "),_c('span',[_vm._v("*")])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.email_template.subject),expression:"email_template.subject"}],class:errors[0] ? 'warning-border':'',attrs:{"type":"text","placeholder":"Subject Character Limit 30","maxlength":_vm.max},domProps:{"value":(_vm.email_template.subject)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.email_template, "subject", $event.target.value)}}})])]}}],null,true)})],1),_c('td',[_c('ValidationProvider',{attrs:{"name":"title","rules":"required|max:30","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"label_and_element_wrapper"},[_c('label',[_vm._v("\n                      Title\n                    "),_c('span',[_vm._v("*")])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.email_template.title),expression:"email_template.title"}],class:errors[0] ? 'warning-border':'',attrs:{"type":"text","placeholder":"Title Character Limit 30","maxlength":_vm.max},domProps:{"value":(_vm.email_template.title)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.email_template, "title", $event.target.value)}}})])]}}],null,true)})],1)]),_c('tr',[_c('td',[_c('ValidationProvider',{attrs:{"name":"description","rules":"required|max:30","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"label_and_element_wrapper"},[_c('label',[_vm._v("\n                    Description\n                    "),_c('span',[_vm._v("*")])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.email_template.description),expression:"email_template.description"}],class:errors[0] ? 'warning-border':'',attrs:{"type":"text","placeholder":"Description Character Limit 60","maxlength":_vm.max2},domProps:{"value":(_vm.email_template.description)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.email_template, "description", $event.target.value)}}})])]}}],null,true)})],1),_c('td',[_c('ValidationProvider',{attrs:{"name":"Mailroom","rules":"required","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"label_and_element_wrapper"},[_c('label',[_vm._v("\n                          Mailroom Location\n                      "),_c('span',[_vm._v("*")])]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.email_template.division_id),expression:"email_template.division_id"}],class:errors[0] ? 'warning-border':'',on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.email_template, "division_id", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":"","disabled":""}},[_vm._v("Select Mailroom Location")]),_vm._l((_vm.divisions),function(division,i){return _c('option',{key:'division_'+i,domProps:{"value":division.id}},[_vm._v(_vm._s(division.name))])})],2)])]}}],null,true)})],1)])])])])]}}])}),_c('section',{staticClass:"centered"},[_c('a',{staticClass:"link_bt bt_save",on:{"click":_vm.submit}},[_vm._v("Save")])])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }